<template>
  <button
    :disabled="disabled"
    :class="{
      'is-loading': loading,
      'has-text-info is-borderless': !label,
      'is-success': label
    }"
    class="button message-submit-button"
    type="button"
    @click="$emit('click')"
  >
    <span v-if="label">{{ label }}</span>
    <b-icon v-else icon="paper-plane" />
  </button>
</template>

<script>
export default {
  name: "PostSubmitButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
